/*         TITULO PRINCIPAL           */

#tituloI {
    margin: 2vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         TEXTO PRINCIPAL           */

#segundoI {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 350px;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
}

/*         IMAGENS EM ORDEM CSS           */
/*         MENU FULL INTERM CRÉDITO           */

#imageIOne {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    height: 20vh;
    margin-left: 45vw;
}

/*         BANCOS           */

#imageITwo {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    height: 20vh;
    width: 65vw;
    margin-left: 22vw;
}

/*         WORKFLOW           */

#imageIThree {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    height: 35vh;
    width: 65vw;
    margin-left: 22vw;
}

/*         WorkflowProcessCredit           */

#imageIFour {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    height: 45vh;
    width: 50vw;
    margin-left: 30vw;
}

/*         Intervenientes           */

#imageIFive {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    height: 60vh;
    width: 55vw;
    padding-bottom: 1vh;
    margin-left: 28vw;
}