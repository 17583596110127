/*         TITULO PRINCIPAL           */

#tituloGE {
    margin: 2vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         TEXTO PRINCIPAL           */

#segundoGE {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 350px;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
    text-align: left;
}

/*         SUBTITULO PRINCIPAL           */

#tituloGESegundo {
    margin: 4.3vh;
    margin-left: 310px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         IMAGENS EM ORDEM CSS           */
/*         GE           */

#imageGEOne {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 45vw;
    height: 35vh;
}

/*         US           */

#imageGETwo {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    height: 25vh;
    margin-left: 25vw;
}

/*         USActions           */


#imageGEThree {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    height: 12vh;
    margin-left: 50vw;
}

/*         USC           */

#imageGEFour {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    height: 25vh;
    margin-left: 25vw;
}

/*         Permissoes           */

#imageGEFive {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    padding-bottom: 5vh;
    height: 45vh;
    margin-left: 30vw;
}