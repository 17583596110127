/*         TITULO PRINCIPAL           */

#tituloFreq {
    margin: 1vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         SUBTITULO PRINCIPAL           */

#tituloFreqSegundo {
    margin: 2.5vh;
    display: flex;
    justify-content: center;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #2c55ca;
    padding-top: 1.5vh;
}

/*           SUBTEXTO          */

#primeiroFreq {
    font-size: 16px;
    color: #5e5e73;
    margin: 2.2vh;
    margin-left: 15%;
    padding-bottom: 1vh;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
    text-align: left;
}

/*         TEXTO PRINCIPAL           */

#segundoFreq {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 350px;
    padding-bottom: 1vh;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    width: 65%;
}

.questionOne {
    width: 45vw;
    display: block;
    margin-left: 30%;
    padding-right: 5vw;
    background-color: rgb(236, 236, 236);
    border-radius: 3vw;
    padding-bottom: 1vh;
    line-height: 3vh;
}

.questionOne li {
    list-style: none;
    margin-left: 5%;
}

.questionTwo {
    width: 50vw;
    display: block;
    margin-left: 30%;
    background-color: rgb(236, 236, 236);
    border-radius: 3vw;
    padding-bottom: 1vh;
    line-height: 3vh;
}

.questionTwo li {
    list-style: none;
}

.questionThree {
    width: 50vw;
    display: block;
    margin-left: 30%;
    background-color: rgb(236, 236, 236);
    border-radius: 3vw;
    padding-bottom: 1vh;
    line-height: 3vh;
}

.questionThree li {
    list-style: none;
}

.questionFour {
    width: 50vw;
    display: block;
    margin-left: 30%;
    background-color: rgb(236, 236, 236);
    border-radius: 3vw;
    padding-bottom: 1vh;
    line-height: 3vh;
}

.questionFour li {
    list-style: none;
}

.questionFive {
    width: 50vw;
    display: block;
    margin-left: 30%;
    background-color: rgb(236, 236, 236);
    border-radius: 3vw;
    padding-bottom: 1vh;
    margin-bottom: 3vh;
    line-height: 3vh;
}

.questionFive li {
    list-style: none;
}