/*     TITULO PRINCIPAL     */

#titulotec {
    margin: 2vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*     TEXTO     */

#primeirotec {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 350px;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
    text-align: left;
}

/*  Imagem do STecnico   */

#imagestec {
    display: block;
    margin: 0 auto;
    padding: 0;
    padding-top: 2vh;
    width: 25vw;
    margin-left: 40vw;
}

/*   Imagem dos Updates   */

#imagesatuali {
    display: block;
    margin: 0 auto;
    padding-top: 2vh;
    padding-bottom: 3vh;
    width: 65vw;
    margin-left: 27vw;
}