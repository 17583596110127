/*         TITULO PRINCIPAL           */

#tituloEvent {
    margin: 2vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         TEXTO PRINCIPAL           */

#segundoEvent {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 350px;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
    text-align: left;
}

/* CSS por ordem de imagens */
/*          Eventos, Form          */

#imagesEvent {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 70vw;
    margin-left: 22vw;
}

/*          NewEvent          */

#imagesEventOne {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    height: 60vh;
    margin-left: 40vw;
}

/*          Forms          */

#imagesEventTwo {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    padding-bottom: 4vh;
    height: 50vh;
    margin-left: 35vw;
}