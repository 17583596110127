#myBtn {
  display: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #2c55ca;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 50px;
  font-size: 18px;
  width: 55px;
  height: 55px;

}

#myBtn:hover {
  background-color: #4caf50;
}