/*         TITULO PRINCIPAL           */

#tituloBd {
    margin: 2vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         TEXTO PRINCIPAL           */

#segundoBd {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 350px;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
    text-align: left;
}

/*         SUBTITULO PRINCIPAL           */

#tituloBdSegundo {
    margin: 4.3vh;
    margin-left: 310px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         IMAGENS EM ORDEM CSS, DESCRITAS PELO NOME DAS IMAGENS           */
/*         BaseDados           */

#imageOne {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    height: 35vh;
    width: 70vw;
    margin-left: 23vw;
}

/*         Entidades, Painel, Transfer, IndividualTransfer, Share, Edição, EBD            */

#imageTwo {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    padding-bottom: 2%;
    margin-left: 40vw;
}

/*         SearchUsers           */

#imageThree {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 40vw;
}

/*         Actions           */

#imageFour {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 10vw;
    margin-left: 40vw;
}

/*         User, NewListOne           */

#imageFive {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 55%;
    margin-left: 30vw;
}

/*         OptionBar               */

#imageSix {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 45vw;
    margin-left: 30vw;
}

/*         TransferOne, Filtro, NameList         */

#imageSeven {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 40vw;
    height: 30vh;
}

/*      Timeline, Nota, Tarefas, Morada, Familia, SMS, Email, Docs, Subcontatos, Lista, 
        NewList, CreateList, Separador, Gerir, ENACampanhas, PAT*/

#imageEight {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 75vh;
    margin-left: 35vw;
}


#imageNine {
    display: block;
    margin: 0 auto;
    height: 25vh;
    margin-left: 35vw;
}

#imageTen {
    display: block;
    margin: 0 auto;
    height: 20vh;
    margin-left: 35vw;
}

#imageEleven {
    display: block;
    margin: 0 auto;
    height: 30vh;
    margin-left: 25vw;
}

/*    Confirmation    */

#imageTwelve {
    display: block;
    margin: 0 auto;
    height: 15vh;
    margin-left: 40vw;
}

/*      GerirLista, CNCampanhas, PAPerfil     */

#imageThirteen {
    display: block;
    margin: 0 auto;
    height: 20vh;
    margin-left: 40vw;
}

#imageFourteen {
    display: block;
    margin: 0 auto;
    height: 10vh;
    margin-left: 40vw;
}

#imageFifthteen {
    display: block;
    margin: 0 auto;
    height: 25vh;
    margin-left: 40vw;
}

#imageSixteen {
    display: block;
    margin: 0 auto;
    height: 45vh;
    margin-left: 30vw;
}

#imageSeventeen {
    display: block;
    margin: 0 auto;
    height: 4.5vh;
    margin-left: 25vw;
}


@media only screen and (max-width: 800px) {
    #tituloBd {
        margin: 2vw 2vw;
        font-size: 3.1vh;
        font-family: 'Open Sans', sans-serif;
        color: #4CAF50;
    }

    /*         TEXTO PRINCIPAL           */

    #segundoBd {
        font-size: 2.2vh;
        color: #000000;
        margin: 1.5vw;
        margin-left: 6vw;
        font-family: 'Open Sans', sans-serif;
        width: 85vw;
        text-align: left;
        ;
    }

    /*         SUBTITULO PRINCIPAL           */

    #tituloBdSegundo {
        margin: 4.3vh;
        margin-left: 4.5vw;
        font-size: 3.1vh;
        font-family: 'Open Sans', sans-serif;
        color: #4CAF50
    }

    /*         IMAGENS EM ORDEM CSS, DESCRITAS PELO NOME DAS IMAGENS           */
    /*         BaseDados           */

    #imageOne {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
        height: 45vh;
        width: 80vw;

    }

    /*         Entidades, Painel, Transfer, IndividualTransfer, Share, Edição, EBD            */

    #imageTwo {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
    }

    /*         SearchUsers           */

    #imageThree {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
    }

    /*         Actions           */

    #imageFour {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
        width: 11vw;
    }

    #lista li {
        list-style: circle;
        font-size: 1.2vw;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif;
        margin-left: 13vw;
    }

    /*         User, NewListOne           */

    #imageFive {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
        width: 65vw;
    }

    /*         OptionBar               */

    #imageSix {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
        width: 50vw;
    }

    /*         TransferOne, Confirmation, Filtro, NameList, CNCampanhas, PAPerfil         */

    #imageSeven {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
    }

    /*      Timeline, Nota, Tarefas, Morada, Familia, SMS, Email, Docs, Subcontatos, Lista, 
            NewList, CreateList, Separador, Gerir, GerirLista, ENACampanhas, PAT*/

    #imageEight {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
        width: 75vh;
    }


    #imageNine {
        display: block;
        margin: 0 auto;
        height: 30vh;
    }
}