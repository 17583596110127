/*         TITULO PRINCIPAL           */

#tituloCR {
    margin: 2vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         TEXTO PRINCIPAL           */

#segundoCR {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 350px;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
}

/*         SUBTITULO PRINCIPAL           */

#tituloCRSegundo {
    margin: 4.3vh;
    margin-left: 310px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         IMAGENS EM ORDEM CSS           */
/*         CRM           */

#imageCROne {
    display: block;
    margin: 0 auto;
    margin-left: 40vw;
    padding-top: 0.5vh;
}

#imageCOne {
    display: block;
    margin: 0 auto;
    margin-left: 40vw;
    padding-top: 0.5vh;
}

/*         WORKFLOW CRM           */

#imageCTwo {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    padding-bottom: 4vh;
    width: 65vw;
    margin-left: 25vw;
}

/*         WORKFLOW PROCESS           */

#imageCThree {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 22vw;
    margin-left: 40vw;
}

#imageCThre {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 15vw;
    margin-left: 40vw;
}

/*          EDICAO PROCESSO           */

#imageCFour {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 65vw;
    margin-left: 25vw;
}

/*          EMAIL           */

#imageCFive {
    display: block;
    margin: 0 auto;
    margin-left: 45vw;
    padding-top: 0.5vh;
    width: 15vw;
}

/*          Style Email           */

#imageCSix {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 45vw;
}

/*          Report PAT           */

#imageCSeven {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 30vw;
}

#imageCEight {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 45vw;
}

#imageCNine {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 40vw;
    height: 25vh;
}

#imageCTen {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 40vw;
    height: 30vh;
}

#imageCEleven {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 40vw;
    height: 50vh;
}