* {
  margin: 0;
}

/* Navbar fixa e tamanho de 100% */
.sidenav {
  height: 100%;
  width: 245px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: #2c55ca;
  overflow-x: hidden;
}

/* Estilização dos botões */
.sidenav a {
  padding: 3px 2px 2px 10px;
  text-decoration: none;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  letter-spacing: 0.03vh;
  color: #d5ddf4;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  height: 4vh;
  margin-top: 12px;
  line-height: 4.2vh;
}

/* Efeitos dos botões por ordem */

.sidenav a:hover {
  background-color: #4266CF;
  border-radius: 50px;
  width: 90%;
}

.sidenav a:active {
  border-radius: 50px;
  background-color: #4caf50;
  width: 90%;
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%);
}

.sidenav a:focus {
  border-radius: 50px;
  background-color: #4caf50;
  width: 90%;
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%);
}


/* Estilizar os icones */

#navIcons {
  float: left;
  display: block;
  color: white;
  padding-right: 8px;
  padding-top: 0.4vh;
  font-size: 25px;
}

#navIconTitle {
  float: left;
  display: block;
  color: white;
  padding-left: 25px;
  padding-top: 16px;
  font-size: 27px;
}

/* Estilizar o botão de suporte */

#support {
  font-size: 14px;
  margin-left: 60px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 85px;
  height: auto;
  background-color: #4caf50;
  border-radius: 50px;
  display: block;
}

#support:hover {
  background-color: #ff3600;
  width: 85px;
}

hr {
  width: 90%;
  margin-left: 3.5%;
  margin-top: 1vh;
}

.titleMenu {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  color: white;
  padding-left: 60px;
  padding-top: 2vh;
  display: block;
}

/* @media only screen and (max-width: 800px) {
  .navBar {
    background-color: #1A3783;
    height: 5vh;
    padding-top: 5vh;
    padding-left: 3vw;
    transition: 2s;
    width: 58%;
  }

  .navBar a {
    float: center;
    color: white;
    font-size: 55%;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    padding: 0 1.2vw;
    transition: 2s;
  }

  .navBar a:hover {
    background-color: #4caf50;
  }
} */