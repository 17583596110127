/*         TITULO PRINCIPAL           */

#tituloProc {
    margin: 2vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         TEXTO PRINCIPAL           */

#segundoProc {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 350px;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
}

/*         SUBTITULO PRINCIPAL           */

#tituloProcSegundo {
    margin: 4.3vh;
    margin-left: 310px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         IMAGENS EM ORDEM CSS           */
/*         PROCESSUAL           */

#imageProcOne {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    height: 6vh;
    margin-left: 45vw;
}


/*         WORKFLOW PROCESSUAL           */

#imagePROCTwo {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    padding-bottom: 4vh;
    width: 60vw;
    margin-left: 27vw;
}

/*         WORKFLOW PROCESS           */

#imagePROCThree {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 40vw;
    height: 70vh;
}

#imagePROCThre {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 15vw;
    margin-left: 40vw;
}

/*          EDICAO PROCESSO           */

#imagePROCFour {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 25vw;
    width: 60vw;
}

#imagePROCFor {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 22vw;
    height: 6vh;
}

/*          EMAIL           */

#imagePROCFive {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 15vw;
    margin-left: 45vw;
}

/*          Style Email           */

#imagePROCSix {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 45vw;
}

/*          Report PAT           */

#imagePROCSeven {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 30vw;
}

#imagePROCEight {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 30vw;
}

#imagePROCNine {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 40vw;
}

#imagePROCTen {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 35vw;
    height: 35vh;
}

#imagePROCEleven {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 45vw;
    height: 9vh;
}

#imagePROCTwelve {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 40vw;
    height: 30vh;
}