/*         TITULO PRINCIPAL           */

#tituloPR {
    margin: 2vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         TEXTO PRINCIPAL           */

#segundoPR {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 350px;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
    text-align: left;
}

/*         SUBTITULO PRINCIPAL           */

#tituloPRSegundo {
    margin: 4.3vh;
    margin-left: 310px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         IMAGENS EM ORDEM CSS           */
/*         ALL APÓS PROJECTOS           */

#imagePROne {
    display: block;
    margin: 0 auto;
    width: 60vw;
    margin-left: 22vw;
    padding-bottom: 4vh;
}

/*         PROJECTOS           */

#imagePRTwo {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 11vw;
    margin-left: 40vw;
}

/*         DocOptions           */

#imagePRThree {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    margin-left: 40vw;
}