/* Navbar fixa e tamanho de 100% */
.sidenavInitial {
  height: 100%;
  width: 285px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: #2c55ca;
  overflow-x: hidden;
}

/* Estilização dos botões */
.sidenavInitial a {
  padding: 3px 2px 2px 10px;
  text-decoration: none;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  letter-spacing: 0.03vh;
  color: #d5ddf4;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  height: 4vh;
  margin-top: 12px;
  line-height: 4.1vh;
}

/* Efeitos dos botões por ordem */

.sidenavInitial a:hover {
  background-color: #4266CF;
  border-radius: 50px;
  width: 90%;
}

.sidenavInitial a:active {
  border-radius: 50px;
  background-color: #4caf50;
  width: 90%;
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%);
}

.sidenavInitial a:focus {
  border-radius: 50px;
  background-color: #4caf50;
  width: 90%;
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%);
}


/* Estilizar os icones */

#navIconsInitial {
  float: left;
  display: block;
  color: white;
  padding-right: 8px;
  padding-top: 0.4vh;
  font-size: 25px;
}

#navIconTitleInitial {
  float: left;
  display: block;
  color: white;
  padding-left: 40px;
  padding-top: 17px;
  font-size: 27px;
}

/* Estilizar o botão de suporte */

#supportInitial {
  font-size: 14px;
  margin-left: 80px;
  margin-top: 6px;
  margin-bottom: 10px;
  padding-left: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 85px;
  height: auto;
  background-color: #4caf50;
  border-radius: 50px;
  display: block;
}

#supportInitial:hover {
  background-color: #ff3600;
  width: 85px;
}

hr {
  width: 90%;
  margin-left: 3.5%;
  margin-top: 1vh;
}

.titleMenuInitial {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  color: white;
  padding-left: 80px;
  padding-top: 20px;
  display: block;
}

.spot {
  position: relative;
  display: block;
  width: 86vw;
  float: right;
  height: 100vh;
  background-color: #1A3783;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  width: 70vw;
}

.social {
  width: 100%;
  height: 20vh;
  padding-top: 30vh;
  margin-left: 35%;
}

#contactInitial {
  font-size: 21px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  padding-top: 2vh;
  letter-spacing: 0.35vh;
  font-family: 'Open Sans', sans-serif;
}

.steps {
  display: flex;
  flex-direction: column;
  padding-left: 65%;
  padding-top: 6%;
  width: 2%;
}

#questions {
  width: 7vw;
  height: 18vh;
  margin-left: 15vw;
}


.contacto {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6vh;
  margin-left: 17%;
}

.contacto a {
  margin-left: 15px;
  padding-bottom: 3vh;
}

.contacto i {
  color: white;
  font-size: 2vw;
  padding-bottom: 1vh;
}

.email {
  display: flex;
  align-items: center;
  margin-left: 17%;
}

.email a {
  margin-left: 20px;
  padding-bottom: 3vh;
}

.email i {
  color: white;
  font-size: 2vw;
  padding-bottom: 1vh;
}


@media only screen and (max-width: 800px) {

  .sidebar {
    position: fixed;
    width: 40%;
    height: 110vh;
    background: #112479;
    font-size: 2vw;
    font-family: 'Open Sans', sans-serif;
  }

  .nav {
    position: relative;
    text-align: left;
    ;
    top: 47vh;
    transform: translateY(-50%);
    font-weight: bold;
  }

  .spot {
    position: relative;
    width: 70vw;
    float: right;
    height: 110vh;
    background-color: #1A3783;
  }

  .social {
    width: 95%;
    height: 95px;
    padding-top: 6%;
    margin-left: 8%;
  }

  #cloudNav {
    margin-top: 15vh;
    margin-right: 45vw;
    height: 50px;
    width: 75px;
  }

  #phoneInitial {
    font-size: 20px;
    padding-bottom: 2vh;
  }

  #mailInitial {
    font-size: 20px;
    padding-bottom: 2vh;
  }

  #contactInitial {
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    padding-top: 1.2vh;
    letter-spacing: 0.25vh;
    font-family: 'Open Sans', sans-serif;
    margin-left: 2vw;
  }

  .steps {
    display: flex;
    flex-direction: column;
    width: 155px;
    padding-left: unset;
  }

  #questions {
    width: 100%;
    height: 70px;
    padding-left: unset;
  }

  .step-by-step {
    color: white;
    font-size: 10px;
    width: 120%;
    letter-spacing: 0.1vw;
    font-family: 'Open Sans', sans-serif;
    margin-left: 20%;
    padding: unset;
    justify-content: center;
  }
}