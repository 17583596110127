/*         TITULO PRINCIPAL           */

#tituloCL {
    margin: 2vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         TEXTO PRINCIPAL           */

#segundoCL {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 350px;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
    text-align: left;
}

/*         SUBTITULO PRINCIPAL           */

#tituloCLSegundo {
    margin: 4.3vh;
    margin-left: 310px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50
}

/*         IMAGENS EM ORDEM CSS           */
/*         CLASSIFICADORES           */
#imageCLOn {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 10vw;
    margin-left: 45vw;
}

/*         CAMPOS PERSONALIZADOS, CARGOS          */

#imageCLOne {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 72vw;
    height: 32vh;
    margin-left: 22vw;
}

/*         ALL APÓS DOCUMENTOS ENTIDADE           */

#imageCLTwo {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    padding-bottom: 5vh;
    height: 35vh;
    width: 70vw;
    margin-left: 22vw;
}

#imageCLThree {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    padding-bottom: 5vh;
    height: 50vh;
    width: 65vw;
    margin-left: 22vw;
}

#imageCLFour {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    padding-bottom: 5vh;
    height: 45vh;
    width: 67vw;
    margin-left: 22vw;
}