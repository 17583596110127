* {
    margin: 0;
}

/*         TITULO PRINCIPAL           */

#titulodash {
    margin: 2vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         SUBTITULO PRINCIPAL           */

#titulodashsegundo {
    margin: 4.3vh;
    margin-left: 310px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50
}

/*           SUBTEXTO          */

#primeirodash {
    font-size: 16px;
    color: #5e5e73;
    margin: 2.2vh;
    margin-left: 350px;
    padding-bottom: 1vh;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
    text-align: left;
    ;
}

/*         TEXTO PRINCIPAL           */

#segundodash {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 320px;
    text-align: left;
    ;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
}

/*         NOTAS           */

#terceirodash {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: red;
    margin-left: 350px;
    width: 70%;
    text-align: left;
    ;
}

/* CSS por ordem de imagens */
/*          LOGIN          */

#imagesdashoito {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 25%;
    height: auto;
    margin-left: 42vw;
}

/*          PPEMPRESA          */

#imagesdashnove {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 55%;
    height: auto;
    margin-left: 30vw;
}

/*          PPUSER          */

#imagesdashdez {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 60%;
    height: auto;
    margin-left: 27vw;
}


#imagesdashonze {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    width: 12%;
    height: auto;
    margin-left: 50vw;
}

/*          DASHBOARD          */

#imagesdash {
    display: block;
    margin: 0 auto;
    width: 60%;
    height: auto;
    margin-left: 26vw;
}

#imagesdashdois {
    display: block;
    margin: 0 auto;
    padding-top: 2vh;
    margin-left: 45vw;
    width: 15%;
    height: auto;
}

/*          BARRA DE CONTABILIZAÇÃO DE TAREFAS          */

#imagesdashtres {
    display: block;
    margin: 0 auto;
    padding-top: 2vh;
    margin-left: 40vw;
    width: 18%;
    height: auto;
}

/*          TASKS          */

#imagesdashquatro {
    display: block;
    margin: 0 auto;
    padding-top: 2vh;
    width: 25%;
    height: auto;
    margin-left: 40vw;
}

#imagesdashcinco {
    display: block;
    margin: 0 auto;
    padding-top: 2vh;
    width: 25%;
    height: auto;
    margin-left: 40vw;
}

/*          GERAR          */

#imagesdashseis {
    display: block;
    margin: 0 auto;
    padding-top: 2vh;
    height: 8vh;
    margin-left: 50vw;
}

/*          NAV          */

#imagesdashsete {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    height: 6vh;
    width: 65vw;
    margin-left: 22vw;
}

/*          CONTACT AND SEARCH          */

#imagesdashdoze {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    padding-bottom: 1.5vh;
    margin-left: 40vw;
}

#imagesdashtreze {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    height: 30vh;
    width: 65vw;
    margin-left: 22vw;
}

#imagesdashcatorze {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    height: 40vh;
    width: 65vw;
    margin-left: 22vw;
}

#imagesdashquinze {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    height: 50vh;
    margin-left: 25vw;
}

/*        LISTA DE TAREFAS        */
#primeirospan {
    color: red;
    font-weight: bold;
    font-size: 16px;
    margin-left: 35vw;
}

#segundospan {
    color: #ffcb1f;
    font-weight: bold;
    font-size: 16px;
    margin-left: 35vw;
}

#terceirospan {
    color: #1e9aff;
    font-weight: bold;
    font-size: 16px;
    margin-left: 35vw;
}

#texto li {
    list-style: none;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    color: #5e5e73;
}

#colorRed {
    color: red;
    font-weight: bold;
}

@media only screen and (max-width: 800px) {

    #root,
    html,
    body {
        width: unset;
    }

    /*         TITULO PRINCIPAL           */

    #titulodash {
        margin: 1vw 2vw;
        font-size: 3.2vh;
        font-family: 'Open Sans', sans-serif;
        color: #4CAF50;
    }

    /*         SUBTITULO PRINCIPAL           */

    #titulodashsegundo {
        margin: 4.3vh;
        margin-left: 3.2vw;
        font-size: 2vh;
        font-family: 'Open Sans', sans-serif;
        color: #4CAF50
    }

    /*           SUBTEXTO          */

    #primeirodash {
        font-size: 2.3vh;
        color: #000000;
        margin: 2.2vh;
        margin-left: 5vw;
        padding-bottom: 1vh;
        font-family: 'Open Sans', sans-serif;
        width: 85vw;
        text-align: left;
        ;
    }

    /*         TEXTO PRINCIPAL           */

    #segundodash {
        font-size: 2.3vh;
        color: #000000;
        margin: 1.5vw;
        margin-left: 6vw;
        text-align: left;
        ;
        font-family: 'Open Sans', sans-serif;
        width: 85vw;
    }

    /*         NOTAS           */

    #terceirodash {
        font-size: 2.4vh;
        font-family: 'Open Sans', sans-serif;
        color: red;
        margin-left: 8vw;
        width: 80vw;
        text-align: left;
        ;
    }

    /* CSS por ordem de imagens */
    /*          LOGIN          */

    #imagesdashoito {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
        height: 65vh;
        width: 5%;
    }

    /*          PPEMPRESA          */

    #imagesdashnove {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
        height: 5vh;

    }

    /*          PPUSER          */

    #imagesdashdez {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
        height: 45vh;
        width: 10%;
    }


    #imagesdashonze {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
        width: 10%;
    }

    /*          DASHBOARD          */

    #imagesdash {
        display: block;
        margin: 0 auto;
        height: 60vh;
        width: 5%;
    }

    #imagesdashdois {
        display: block;
        margin: 0 auto;
        padding-top: 2vh;
        width: 5%;
    }

    /*          BCT          */

    #imagesdashtres {
        display: block;
        margin: 0 auto;
        padding-top: 2vh;
        width: 5%;
    }

    /*          TASKS          */

    #imagesdashquatro {
        display: block;
        margin: 0 auto;
        padding-top: 2vh;
        height: 65vh;
        width: 5%;
    }

    #imagesdashcinco {
        display: block;
        margin: 0 auto;
        padding-top: 2vh;
        height: 70vh;
        width: 5%;
    }

    /*          GERAR          */

    #imagesdashseis {
        display: block;
        margin: 0 auto;
        padding-top: 2vh;
        height: 8vh;

    }

    /*          NAV          */

    #imagesdashsete {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
        height: 7vh;
        width: 20vw;
    }

    /*          CONTACT AND SEARCH          */

    #imagesdashdoze {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
        padding-bottom: 1.5vh;
        width: 5%;
    }

    #imagesdashtreze {
        display: block;
        padding-top: 0.5vh;
        height: 35vh;
        width: 70vw;
        margin: unset;
    }

    #imagesdashcatorze {
        display: block;
        margin: 0 auto;
        padding-top: 0.5vh;
        height: 45vh;
        width: 70vw;
        margin: unset;
    }

    /*        LISTA DE TAREFAS        */
    #primeirospan {
        color: red;
        font-weight: bold;
        font-size: 1.1vw;
        margin-left: 30vw;
    }

    #segundospan {
        color: #ffcb1f;
        font-weight: bold;
        font-size: 1.1vw;
        margin-left: 30vw;
    }

    #terceirospan {
        color: #1e9aff;
        font-weight: bold;
        font-size: 1.1vw;
        margin-left: 30vw;
    }

    #texto li {
        list-style: none;
        font-size: 1vw;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif;
    }

    #colorRed {
        color: red;
        font-weight: bold;
    }
}