/*         TITULO PRINCIPAL           */

#tituloProj {
    margin: 2vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         TEXTO PRINCIPAL           */

#segundoProj {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 350px;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
}

/*         SUBTITULO PRINCIPAL           */

#tituloProjSegundo {
    margin: 4.3vh;
    margin-left: 310px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         IMAGENS EM ORDEM CSS, DESCRITAS PELO NOME DAS IMAGENS           */
/*         PROJECTOS           */

#imageProjOne {
    display: block;
    margin: 0 auto;
    padding-top: 0.5vh;
    height: 6vh;
    margin-left: 45vw;
}

/*         Artigos, NewDoc, Obra           */

#imageProjTwo {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    height: 38vh;
    width: 65vw;
    margin-left: 25vw;
}

/*         NewArtigo           */

#imageProjThree {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    height: 55vh;
    width: 55vw;
    margin-left: 30vw;
}

/*         DocFinanceiros, Tecnica           */

#imageProjFour {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    padding-bottom: 4vh;
    height: 32vh;
    width: 63vw;
    margin-left: 25vw;
}

/*         Areas           */

#imageProjFive {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    height: 25vh;
    width: 12vw;
    margin-left: 45vw;
}

/*         NewObra, Anexos, Fluxos, Materiais, AnaliseOption            */

#imageProjSix {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    width: 35%;
    margin-left: 30vw;
}

/*         ObraActions           */

#imageProjSeven {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    height: 10vh;
    margin-left: 40vw;
}

/*         FichaCliente, AreaComercial            */

#imageProjEight {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    width: 25vw;
    margin-left: 35vw;
}

/*         Analise, AnaliseTwo, Grafico            */

#imageProjNine {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    width: 50vw;
    margin-left: 30vw;
}

/*         NavActions            */

#imageProjTen {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    margin-left: 30vw;
}

#imageProjEleven {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    margin-left: 45vw;
}

#imageProjTwelve {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    width: 70%;
    margin-left: 22vw;
}

#imageProjThirdteen {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    width: 45vw;
    margin-left: 30vw;
}

#imageProjFourteen {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    width: 42vw;
    margin-left: 33vw;
}

#imageProjFifthteen {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    width: 60vw;
    height: 30vh;
    margin-left: 25vw;
}

#imageProjSixteen {
    display: block;
    margin: 0 auto;
    padding-top: 0.2vh;
    width: 25vw;
    height: 30vh;
    margin-left: 36vw;
}