/*         TITULO PRINCIPAL           */

#tituloFicha {
    margin: 2vw 2vw;
    margin-left: 285px;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    color: #4CAF50;
}

/*         TEXTO PRINCIPAL           */

#segundoFicha {
    font-size: 16px;
    color: #5e5e73;
    margin: 1.5vw;
    margin-left: 350px;
    font-family: 'Open Sans', sans-serif;
    width: 70%;
    text-align: left;
}

#imagesFicha {
    display: block;
    margin: 0 auto;
    margin-left: 28vw;
    padding-top: 2vh;
    width: 60vw;

}